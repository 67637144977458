export enum CalendarType {
  Month = "month",
  Week = "week",
  Day = "day",
}

export default function useCalendar() {
  const types: CalendarType[] = [
    CalendarType.Month,
    CalendarType.Week,
    CalendarType.Day,
  ];

  const typeNames: { [key in CalendarType]: string } = {
    [CalendarType.Month]: "Miesiąc",
    [CalendarType.Week]: "Tydzień",
    [CalendarType.Day]: "Dzień",
  };

  return { types, typeNames };
}
