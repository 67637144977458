



































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import useCalendar, { CalendarType } from "@/use/calendar";
import useApi from "@/use/api";
import useUser from "@/use/user";
import { nextTick } from "process";
import _ from "lodash";

export interface EventItem {
  name: string;
  start: Date | string;
  end: Date | string;
  timed: boolean;

  reservation: any;
}

export default defineComponent({
  components: {
    MUserInput: () => import("@/components/molecules/m-user-input.vue"),
    MDatePicker: () => import("@/components/molecules/m-date-picker.vue"),
    MTimePicker: () => import("@/components/molecules/m-time-picker.vue"),
  },
  props: {
    id: {
      type: String,
      required: false,
      default: undefined,
    },
    company: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup(props, { root }) {
    const today = root.$moment().format("YYYY-MM-DD");

    const { types, typeNames } = useCalendar();
    const { axiosInstance } = useApi({ root });
    const { currentUser, currentCompany, hasAccessTo } = useUser({ root });

    const error = ref<string | null>(null);

    const form = ref();
    const isFormValid = ref(true);

    const deleteDialog = ref(false);

    const selected = reactive({
      open: false,
      event: null as EventItem | null,
      element: null as Element | null,
    });

    const dialog = reactive({
      key: new Date().getTime(),
      open: false,
      model: {
        user: null as any,
        date: [today, today],
        time: ['01:00', '01:00'],
        timed: false
      },
    });

    watch(
      () => dialog.model.date,
      (newValue, oldValue) => {
        if (!_.isEqual(newValue, oldValue)) dialog.model.date.sort()
      },
      { deep: true }
    );

    const resetDialog = () => {
      dialog.model.user = null;
      dialog.model.date = [today, today];

      setTimeout(() => (dialog.key = new Date().getTime()), 500);
    };

    const openDialog = () => {
      dialog.open = true;
    };

    const closeDialog = () => {
      dialog.open = false;
      nextTick(resetDialog);
    };

    const loading = ref(false);
    const calendar = ref();

    const type = ref<CalendarType>(CalendarType.Month);
    const typeName = computed(() => typeNames[type.value]);

    const dateRange = ref();

    const onChange = (value: any) => (dateRange.value = value);

    const focus = ref(today);

    const events = ref<EventItem[]>([]);

    const getEventColor = (event: EventItem) =>
      event.reservation?.user?.id === currentUser.value?.id
        ? "primary"
        : "secondary";

    const fetchData = () => {
      loading.value = true;
      axiosInstance
        .get(`vehicle/${props.id}/reservation`, {
          params: dateRange.value
            ? {
                from: `${dateRange.value.start.date || today}T00:00:00.000`,
                to: `${dateRange.value.end.date || today}T23:59:59.999`,
              }
            : {},
        })
        .then(({ data: { reservations } }) => {
          events.value = reservations.map((reservation: any) => ({
            name: reservation.user
              ? `${reservation.user.firstName} ${reservation.user.lastName}`
              : "???",
            start: new Date(reservation.start),
            end: new Date(reservation.end),
            timed: reservation.timed,
            reservation,
          }));
        })
        .catch(() => (events.value = []))
        .finally(() => (loading.value = false));
    };

    onMounted(fetchData);
    watch(dateRange, fetchData);
    watch(() => props.id, fetchData);

    const onSubmit = async () => {
      error.value = null;

      await form.value?.validate();

      if (isFormValid.value) {
        loading.value = true;

        await axiosInstance
          .post(`vehicle/${props.id}/reservation`, {
            user: dialog.model.user?.id || undefined,
            start: `${dialog.model.date[0] || today}T${dialog.model.time[0] || '01:00'}`,

            end: `${dialog.model.date[1] || dialog.model.date[0] || today}T${dialog.model.time[1] || '01:00'}`,
            timed: dialog.model.timed || false
          })
          .then(() => {
            closeDialog();
            fetchData();
          })
          .catch(() => {
            error.value = 'Nie można dokonać rezerwacji'
          })
          .finally(() => (loading.value = false));
      }
    };

    const deleteReservation = (id?: string) => {
      if (id) {
        loading.value = true
        axiosInstance
          .delete(`vehicle/reservation/${id}`)
          .then(() =>{
            deleteDialog.value = false;
            selected.open = false;
            fetchData();
          })
          .catch(() => [])
          .finally(() => loading.value = false)
      }
    }

    const prev = () => calendar.value?.prev();
    const next = () => calendar.value?.next();
    const reset = () => (focus.value = today);

    const showEvent = ({
      nativeEvent,
      event,
    }: {
      nativeEvent: Event;
      event: EventItem;
    }) => {
      const open = () => {
        (selected.event = event as EventItem),
          (selected.element = nativeEvent.target as HTMLElement);
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (selected.open = true))
        );
      };

      if (selected.open) {
        selected.open = false;
        requestAnimationFrame(() => requestAnimationFrame(open));
      } else {
        open();
      }
    };

    const onDateClick = ({ date }: { date: string }) => {
      dialog.model.date = [root.$moment(date).format("YYYY-MM-DD")];
      openDialog();
    };

    return {
      currentUser,
      currentCompany,
      hasAccessTo,

      error,

      form,
      isFormValid,

      deleteDialog,

      selected,
      dialog,

      openDialog,
      closeDialog,

      types,
      typeNames,

      loading,

      calendar,

      type,
      typeName,

      dateRange,
      onChange,

      focus,
      events,
      getEventColor,
      fetchData,

      onSubmit,
      deleteReservation,

      prev,
      next,
      reset,

      showEvent,
      onDateClick,
    };
  },
});
